//.................................................................................. Response types

import {
  AgencyProfile,
  Asset,
  Order,
  OrderItem,
  PropertyListing,
  User,
  UserToOrganisation,
  VideoTemplate,
} from "@prisma/client"

export type ErrorCodeToDataTypeMap = {
  unknownError: any
  notFound: any
  notLoggedIn: any
  badRequest: any
  forbidden: any
  mailerFailure: any
}

export type ApiErrorResponse<
  CODE extends keyof ErrorCodeToDataTypeMap = keyof ErrorCodeToDataTypeMap
> = {
  errorCode: CODE
  message: string
  data: ErrorCodeToDataTypeMap[CODE]
}

export type PagingParams = {
  limit: number
  offset: number
}

export type ApiSuccessResponse<T, I = Record<string, unknown>> = {
  data: T
  pagingParams?: PagingParams
  included?: I
}

//.......................................................................................Assertions

export const isSuccessResponse = <T, I = Record<string, unknown>>(
  response: ApiSuccessResponse<T, I> | ApiErrorResponse
): response is ApiSuccessResponse<T, I> => {
  return "data" in response && !("errorCode" in response)
}

export const isErrorResponse = (
  response: ApiSuccessResponse<unknown> | ApiErrorResponse
): response is ApiErrorResponse => {
  return "errorCode" in response
}

//.................................................................................. Users and Orgs

export type UserResponse = {
  id: string
  email: string
  givenName: string | null
  familyName: string | null
  externalId: string
}

export function transformUser(user: User): UserResponse {
  return {
    email: user.email,
    externalId: user.external_id,
    familyName: user.family_name,
    givenName: user.given_name,
    id: user.id,
  }
}

export type UserRole = "admin" | "user"

export type ActiveOrgUser = {
  id: string
  userId: string
  inviteEmail: null
  organisationId: string
  role: UserRole
}

export type InvitedOrgUser = {
  id: string
  userId: null
  inviteEmail: string
  organisationId: string
  role: UserRole
}

export type OrgUser = ActiveOrgUser | InvitedOrgUser

export type PatchUserRequest = {
  givenName?: string | null
  familyName?: string | null
}

export type OrganisationResponse = {
  name: string
  createdAt: number
  updatedAt: number
  accountCreditCents: number
}

//.................................................................................... Customer info

export type CurrentUserInfo = {
  ip: {
    ip: string
    country: string | undefined
    region: string | undefined
    city: string | undefined
  }
  onboardData: OnboardData
  user: UserResponse
  organisations: (ActiveOrgUser & { name: string })[]
}

//.................................................................................... Customer info

export type CreateOrgUserRequest = {
  email: string
  role: UserRole
}

export type OrgUsersIncludes = {
  users: UserResponse[]
}

export type PatchOrgUserRequest = {
  role: UserRole
}

//........................................................................................ Billing

export type OrgBilling = {
  orgId: string
  cashBalanceCents: number
  currency: string
  stripeCustomerId: string
  lastFour: string | null
}

export type OrgBillingPatch = {
  stripePaymentMethodId: string | null
}

export type OrgBillingSetupIntent = {
  clientSecret: string
}

//........................................................................................ Onboard

export interface OnboardData {
  id: string
  userId: string
  onboardComplete: boolean
  createdAt: number
  updatedAt: number
}

export interface UpdateRequest {
  onboardComplete?: boolean
}

//.................................................................................. Assets

export type AssetResponse = {
  id: string
  url: string
  type: string
  scrapedPageUrl: string | null
  hasTransparency: boolean
  preferredBackground: "dark" | "light" | null
  // createdAt: number
  // updatedAt: number
}

//.................................................................................. Profiles

export type AgencyProfileColors = {
  lightColor: string
  darkColor: string
  accentColor: string
}

export const defaultAgencyColors: AgencyProfileColors = {
  lightColor: "#f9fafb",
  darkColor: "#111827",
  accentColor: "#374151",
}

export type RealtorProfileResponse = {
  id: string
  userToOrgId: string
  email: string
  phone: string
  createdAt: number
  updatedAt: number
}

export type AgencyProfileResponse = AgencyProfileColors & {
  id: string
  organisationId: string
  companyWebsite: string | null
  creatomateFont: string | null
  city: string
  createdAt: number
  updatedAt: number
}

export function createAgencyProfileResponse(updatedProfile: AgencyProfile): AgencyProfileResponse {
  return {
    id: updatedProfile.id,
    organisationId: updatedProfile.organisation_id,
    companyWebsite: updatedProfile.company_website,
    creatomateFont: updatedProfile.creatomate_font,

    darkColor: updatedProfile.dark_color || defaultAgencyColors.darkColor,
    lightColor: updatedProfile.light_color || defaultAgencyColors.lightColor,
    accentColor: updatedProfile.accent_color || defaultAgencyColors.accentColor,

    city: updatedProfile.city,
    createdAt: Number(updatedProfile.created_at),
    updatedAt: Number(updatedProfile.updated_at),
  }
}

export type RealtorProfileIncluded = {
  primaryHeadshotAssetId?: string
  headshots: Record<string, AssetResponse>
}

export type AgencyProfileIncluded = {
  primaryLogoAssetId?: string
  logos: Record<string, AssetResponse>
}

export type PutLogoRequest = {
  assetId: string
  agencyProfileId: string
  isPrimary?: boolean
}

export type PutHeadshotRequest = {
  assetId: string
  realtorProfileId: string
  isPrimary?: boolean
}

export const transformAsset = (asset: Asset): AssetResponse => ({
  id: asset.id,
  url: asset.url,
  type: asset.content_type,
  scrapedPageUrl: asset.scraped_page_url,
  hasTransparency: asset.has_transparency,
  preferredBackground: asset.preferred_background as "light" | "dark" | null,
  // createdAt: Number(asset.created_at),
  // updatedAt: Number(asset.updated_at),
})

//.................................................................................. Listing

export type ListingSearchResult = {
  address: string
  type: "buy" | "rent"
  imageUrl: string
  source: "rea"
  externalId: string
  listingUrl: string
}

export type ListingDetail = {
  id: string
  address: string
  type: "buy" | "rent"
  source: "rea"
  externalId: string
  listingUrl: string
  imageUrls: string[]
}

//.................................................................................. Payment intents

export interface PaymentIntentResponse {
  requiresPayment: boolean
  clientSecret?: string
  orderId: string
}

export interface PaymentIntentResponseIncluded {
  order: {}
}

export interface CreatePaymentIntentRequest {
  amountCents: number
  checkoutId: string
}

export type OrderPaymentStatus = "pending" | "processing" | "paid" | "failed"

export const orderProcessingStatuses = {
  pending: "pending",
  processing: "processing",
  failed: "failed",
  success: "success",
} as const

export const imageToVideoProviders = ["luma", "runway"] as const

export type ImageToVideoProvider = (typeof imageToVideoProviders)[number]

export type OrderProcessingStatus =
  (typeof orderProcessingStatuses)[keyof typeof orderProcessingStatuses]

export type OrderPaymentSummary = {
  currency: string
  currentAccountCreditCents: number
  itemsCents: number
  extrasCents: number
  itemsAndExtrasCents: number
  discountCents: number
  itemsAndExtrasAndDiscountCents: number
  amountPaidUsingCreditCents: number
  amountPaidUsingCardCents: number
  remainingAccountCreditCents: number
}

//.................................................................................. Audit log data

// key -> data type
export type AuditLogDataMap = {
  order: {}
}

//....................................................................... Property listing response

export type OrderResponseData = {
  id: string
  orgId: string
  userId: string
  address: string
  mainImageUrl: string | null
  paymentStatus: OrderPaymentStatus
  processingStatus: OrderProcessingStatus
  data: Record<string, any> | null
  createdAt: number
  updatedAt: number
}

export type PropertyListingResponse = {
  id: string
  address: string
  type: string
  source: string // 'rea'
  externalId: string
  url: string
  createdAt: number
  updatedAt: number
}

export type OrderIncluded = {
  orderItems: Record<string, OrderItemResponse>
  videoTemplates: Record<string, VideoTemplateResponse>
  creator: UserResponse
  assets: Record<string, AssetResponse>
  propertyListings: Record<string, PropertyListingResponse>
}

export const transformOrder = (order: Order, userToOrg: UserToOrganisation): OrderResponseData => ({
  id: order.id,
  orgId: userToOrg.organisation_id,
  userId: userToOrg.user_id!,
  address: order.address,
  mainImageUrl: order.main_image_url,
  paymentStatus: order.payment_status as OrderPaymentStatus,
  processingStatus: order.processing_status as OrderProcessingStatus,
  data: order.data as Record<string, any> | null,
  createdAt: Number(order.created_at),
  updatedAt: Number(order.updated_at),
})

export const transformPropertyListing = (listing: PropertyListing): PropertyListingResponse => ({
  id: listing.id,
  address: listing.address,
  type: listing.type,
  source: listing.source,
  externalId: listing.external_id,
  url: listing.url,
  createdAt: Number(listing.created_at),
  updatedAt: Number(listing.updated_at),
})

export type VideoTemplateResponse = {
  id: string
  slug: string
  name: string
  isLive: boolean
  sequencingPreferences: Record<string, any> | null
  createdAt: number
  updatedAt: number
}

export type OrderItemResponse = {
  id: string
  orderId: string
  outputAssetId: string | null
  snapshotAssetId: string | null
  videoTemplateId: string | null
  processingStatus: OrderProcessingStatus
  data: Record<string, any> | null
  createdAt: number
  updatedAt: number
}

export const transformVideoTemplate = (template: VideoTemplate): VideoTemplateResponse => ({
  id: template.id,
  slug: template.slug,
  name: template.name,
  isLive: template.is_live,
  sequencingPreferences: template.sequencing_preferences as any,
  createdAt: template.created_at.getTime(),
  updatedAt: template.updated_at.getTime(),
})

export const transformOrderItem = (item: OrderItem): OrderItemResponse => ({
  id: item.id,
  orderId: item.order_id,
  outputAssetId: item.output_asset_id,
  snapshotAssetId: item.snapshot_asset_id,
  videoTemplateId: item.video_template_id,
  processingStatus: item.processing_status as OrderProcessingStatus,
  data: item.data as any,
  createdAt: item.created_at.getTime(),
  updatedAt: item.updated_at.getTime(),
})

export type CheckoutResponse = {
  id: string
  userToOrgId: string
  address: string
  uploadedAssetIds: string[]
  reaListingId: string | null
}

export type CheckoutResponseIncluded = {
  assets: Record<string, AssetResponse>
}
