"use client"

import { useApiGet } from "@/client/hooks/use-api-get"
import { useFunctionRef } from "@/client/hooks/use-function-ref"
import { ActiveOrgUser, CurrentUserInfo, UserResponse } from "@/shared/models/models"
import { getDisplayName, getInitialedDisplayName } from "@/shared/utils/display-names"
import { useRouter, useSearchParams } from "next/navigation"
import { createContext, useContext, useEffect, useState } from "react"

export type OnboardingStage = "user-details" | "agency-details" | undefined

type UserContextState = Partial<CurrentUserInfo> & {
  selectedOrgId?: string
  isLoading: boolean
  error?: string
}

export type UserContextType = UserContextState & {
  selectedOrg?: ActiveOrgUser & { name: string }
  refetch: () => Promise<any>
  selectOrganisation: (orgId: string) => void
}

const UserContext = createContext<UserContextType>({
  isLoading: true,
  refetch: async () => {},
  selectOrganisation: () => {},
})

export function CurrentUserInfoProvider({ children }: { children: React.ReactNode }) {
  const [selectedOrgId, setSelectedOrgId] = useState<string | undefined>()
  const searchParams = useSearchParams()
  const router = useRouter()

  const {
    data: userInfo,
    isLoading,
    error,
    refetch,
  } = useApiGet<CurrentUserInfo>({
    url: "/api/current-user-info",
  })

  useEffect(() => {
    if (!userInfo?.user || !userInfo.organisations) return

    // Select org using org_id param
    const orgIdParam = searchParams.get("org_id")
    if (orgIdParam) {
      // Check if user has access to the org from the query parameter
      const orgFromParam = userInfo.organisations.find((org) => org.organisationId === orgIdParam)

      if (orgFromParam) {
        // User has access, select the org
        setSelectedOrgId(orgFromParam.organisationId)
        localStorage.setItem(`selectedOrg_${userInfo.user.id}`, orgFromParam.organisationId)
      }

      // Remove the org_id parameter from the URL
      const newUrl = new URL(window.location.href)
      newUrl.searchParams.delete("org_id")
      router.replace(newUrl.pathname + newUrl.search)
      return
    }

    if (!selectedOrgId) {
      // No org_id parameter, follow existing logic
      const storedOrgId = localStorage.getItem(`selectedOrg_${userInfo.user.id}`)
      const selectedOrganisation =
        userInfo.organisations.find((org) => org.organisationId === storedOrgId) ||
        userInfo.organisations?.[0]

      if (selectedOrganisation) {
        setSelectedOrgId(selectedOrganisation.organisationId)
      }
    }
  }, [userInfo, searchParams, router, selectedOrgId])

  const selectOrganisation = useFunctionRef((orgId: string) => {
    if (!userInfo?.user) return

    const org = userInfo.organisations?.find((o) => o.organisationId === orgId)
    if (org) {
      localStorage.setItem(`selectedOrg_${userInfo.user.id}`, org.organisationId)
      setSelectedOrgId(org.organisationId)
    }
  })

  const selectedOrg = userInfo?.organisations?.find((org) => org.organisationId === selectedOrgId)

  const contextValue: UserContextType = {
    ip: userInfo?.ip,
    onboardData: userInfo?.onboardData,
    user: userInfo?.user,
    organisations: userInfo?.organisations,
    selectedOrgId,
    isLoading,
    error: error?.message,
    selectOrganisation,
    refetch,
    selectedOrg,
  }

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}

//................................................................................ Helper functions

export const useCurrentUserInfo = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider")
  }

  return context
}

// Utility functions remain unchanged
export function getUserDisplayName(user: UserResponse | undefined): string {
  return getDisplayName(user?.givenName || "", user?.familyName || "", user?.email || "")
}

export function getUserInitialedDisplayName(user: UserResponse | undefined): string {
  return getInitialedDisplayName(user?.givenName || "", user?.familyName || "", user?.email || "")
}

export function useOnboardingStage(): [OnboardingStage, boolean] {
  const { isLoading } = useCurrentUserInfo()
  const stage: OnboardingStage = "agency-details"
  return [stage, isLoading]
}
