import {
  ApiErrorResponse,
  isErrorResponse,
  isSuccessResponse,
  PagingParams,
} from "@/shared/models/models"
import { useEffect, useState } from "react"
import { callApi } from "../call-api"
import { useMounted } from "./use-mounted"

interface UseApiGetState<TResponse, TIncluded = Record<string, unknown>> {
  data: TResponse | null
  included: TIncluded | undefined
  pagingParams: PagingParams | undefined
  error: ApiErrorResponse | null
  isLoading: boolean
}

interface UseApiGetOptions {
  url: string
  queryParams?: Record<string, string | boolean | number>
  enabled?: boolean
}

export function useApiGet<TResponse, TIncluded = Record<string, unknown>>({
  url,
  queryParams,
  enabled = true,
}: UseApiGetOptions) {
  const isMounted = useMounted()
  const [state, setState] = useState<UseApiGetState<TResponse, TIncluded>>({
    data: null,
    included: undefined,
    pagingParams: undefined,
    error: null,
    isLoading: true,
  })

  const fetchData = async () => {
    setState((prev) => {
      return { ...prev, isLoading: true, error: null }
    })

    try {
      const response = await callApi<TResponse, TIncluded>(url, {
        method: "GET",
        queryParams,
      })

      if (!isMounted()) {
        return
      }

      if (isSuccessResponse<TResponse, TIncluded>(response)) {
        const newState = {
          data: response.data,
          included: response.included,
          pagingParams: response.pagingParams,
          error: null,
          isLoading: false,
        }

        setState(newState)
        return response
      } else if (isErrorResponse(response)) {
        const errorState = {
          data: null,
          included: undefined,
          pagingParams: undefined,
          error: response,
          isLoading: false,
        }

        setState(errorState)
      }
    } catch (err) {
      if (!isMounted()) {
        return
      }

      const errorResponse: ApiErrorResponse = {
        errorCode: "unknownError",
        message: err instanceof Error ? err.message : "An unexpected error occurred",
        data: null,
      }

      const errorState = {
        data: null,
        included: undefined,
        pagingParams: undefined,
        error: errorResponse,
        isLoading: false,
      }
      setState(errorState)
      throw errorResponse
    }
  }

  useEffect(() => {
    if (!enabled) return

    fetchData()
  }, [url, enabled, JSON.stringify(queryParams)])

  return {
    ...state,
    refetch: () => fetchData(),
  }
}
