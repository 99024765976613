import { capitalize } from "./strings"

export function getDisplayName(
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null
): string {
  const cleanedFirstName = cleanName(firstName || "")
  const cleanedLastName = cleanName(lastName || "")

  if (cleanedFirstName && cleanedLastName) {
    return `${cleanedFirstName} ${cleanedLastName}`
  }

  if (cleanedFirstName) {
    return cleanedFirstName
  }

  if (cleanedLastName) {
    return cleanedLastName
  }

  if (email) {
    return email.split("@")[0].toLowerCase()
  }

  return ""
}

export function getInitialedDisplayName(
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null
): string {
  const cleanedFirstName = cleanName(firstName || "")
  const cleanedLastName = cleanName(lastName || "")

  if (cleanedFirstName && cleanedLastName) {
    return `${cleanedFirstName} ${cleanedLastName[0]}.`
  }

  return getDisplayName(firstName, lastName, email)
}

export function cleanName(name: string): string {
  // Remove all non-space whitespace and special characters
  return capitalize(name.replace(/[^a-zA-Z\s]/g, "").trim())
}
