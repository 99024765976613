import { ApiErrorResponse, ApiSuccessResponse } from "@/shared/models/models"

export async function callApi<TResponse, TIncluded = Record<string, unknown>>(
  url: string,
  {
    method = "GET",
    body,
    queryParams,
  }: {
    method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH"
    body?: unknown
    queryParams?: Record<string, string | boolean | number>
  }
): Promise<ApiSuccessResponse<TResponse, TIncluded> | ApiErrorResponse> {
  // Add query parameters if they exist
  let finalUrl = url
  if (queryParams && Object.keys(queryParams).length > 0) {
    const searchParams = new URLSearchParams()
    for (const [key, value] of Object.entries(queryParams)) {
      searchParams.set(key, String(value))
    }
    finalUrl = `${url}?${searchParams.toString()}`
  }

  try {
    const response = await fetch(finalUrl, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: body ? JSON.stringify(body) : undefined,
    })

    return await response.json()
  } catch (error) {
    // Handle network or parsing errors
    return {
      errorCode: "unknownError",
      message: error instanceof Error ? error.message : "An unexpected error occurred",
      data: null,
    }
  }
}
