import(/* webpackMode: "eager" */ "/workspace/node_modules/@stackframe/stack/dist/esm/components-page/account-settings.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@stackframe/stack/dist/esm/components-page/auth-page.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@stackframe/stack/dist/esm/components-page/email-verification.js");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorPage"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/components-page/error-page.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@stackframe/stack/dist/esm/components-page/forgot-password.js");
;
import(/* webpackMode: "eager", webpackExports: ["MagicLinkCallback"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/components-page/magic-link-callback.js");
;
import(/* webpackMode: "eager", webpackExports: ["OAuthCallback"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/components-page/oauth-callback.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@stackframe/stack/dist/esm/components-page/password-reset.js");
;
import(/* webpackMode: "eager", webpackExports: ["SignOut"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/components-page/sign-out.js");
;
import(/* webpackMode: "eager", webpackExports: ["SignUp"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/components-page/sign-up.js");
;
import(/* webpackMode: "eager", webpackExports: ["TeamInvitation"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/components-page/team-invitation.js");
;
import(/* webpackMode: "eager", webpackExports: ["CredentialSignIn"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/components/credential-sign-in.js");
;
import(/* webpackMode: "eager", webpackExports: ["CredentialSignUp"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/components/credential-sign-up.js");
;
import(/* webpackMode: "eager", webpackExports: ["MagicLinkSignIn"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/components/magic-link-sign-in.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@stackframe/stack/dist/esm/components/message-cards/message-card.js");
;
import(/* webpackMode: "eager", webpackExports: ["OAuthButtonGroup"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/components/oauth-button-group.js");
;
import(/* webpackMode: "eager", webpackExports: ["OAuthButton"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/components/oauth-button.js");
;
import(/* webpackMode: "eager", webpackExports: ["SelectedTeamSwitcher"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/components/selected-team-switcher.js");
;
import(/* webpackMode: "eager", webpackExports: ["UserButton"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/components/user-button.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@stackframe/stack/dist/esm/providers/stack-provider-client.js");
;
import(/* webpackMode: "eager", webpackExports: ["StackTheme"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/providers/theme-provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationProviderClient"] */ "/workspace/node_modules/@stackframe/stack/dist/esm/providers/translation-provider-client.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"]}],\"variableName\":\"sans\"}");
;
import(/* webpackMode: "eager" */ "/workspace/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["CurrentUserInfoProvider"] */ "/workspace/src/client/components/current-user-info-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoadingOverlay"] */ "/workspace/src/client/components/ui.tsx");
