import { useCallback, useRef } from "react"

export function useFunctionRef<T extends (...args: any[]) => any>(fn: T): T {
  const fnRef = useRef<T>(fn)

  // Always keep the ref up to date
  fnRef.current = fn

  // We can use useCallback without dependencies because we're
  // always reading the latest function from the ref
  return useCallback((...args: Parameters<T>): ReturnType<T> => {
    return fnRef.current(...args)
  }, []) as T
}
