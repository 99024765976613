export function capitalize(s: string): string {
  const lower = s.toLowerCase()
  // split on white space
  const words = lower.split(" ")

  // capitalize each word
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })

  // join the words back together
  return capitalizedWords.join(" ")
}

export function removeLeadingSlashes(path: string): string {
  return path.replace(/^\/+/, "")
}

/**
 * Converts a string into a URL-friendly slug.
 * - Converts to lowercase
 * - Replaces spaces and special characters with hyphens
 * - Removes diacritics (accents)
 * - Removes non-alphanumeric characters
 * - Collapses multiple hyphens into a single hyphen
 * - Trims hyphens from start and end
 *
 * @param str - The string to convert to a slug
 * @returns A URL-friendly slug string
 */
export function slugify(str: string): string {
  return (
    str
      // Convert to lowercase
      .toLowerCase()
      // Remove diacritics
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      // Replace spaces and special chars with hyphens
      .replace(/[^a-z0-9]+/g, "-")
      // Collapse multiple hyphens
      .replace(/-+/g, "-")
      // Remove leading and trailing hyphens
      .replace(/^-+|-+$/g, "")
  )
}

/**
 *
 */
export function slugifyPath(path: string): string {
  // Remove all instances of multiple slashes
  path = path.replace(/\/{2,}/g, "/")

  // Remove leading and trailing slashes
  path = path.replace(/^\/+|\/+$/g, "")

  return path.split("/").map(slugify).join("/")
}

/**
 * Cleans a website URL by removing the scheme (http/https) and path while preserving www and converting to lowercase
 * @param url The URL to clean
 * @returns The cleaned domain name
 * @example
 * cleanWebsite('https://www.Example.com/path') // returns 'www.example.com'
 * cleanWebsite('http://sub.domain.co.uk:8080/test') // returns 'sub.domain.co.uk'
 */
export function cleanWebsite(url: string): string {
  try {
    // Remove whitespace
    url = url.trim()

    // Handle empty or invalid input
    if (!url) {
      return ""
    }

    // Remove scheme but preserve www if present
    url = url.replace(/^https?:\/\//i, "")

    // Remove everything after the first slash or colon
    url = url.split(/[/:]/)[0]

    // Convert to lowercase
    return url.toLowerCase()
  } catch (error) {
    return ""
  }
}

export function getTrailingDigits(str: string): string {
  const match = str.match(/\d+$/)
  return match ? match[0] : ""
}
